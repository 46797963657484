/* Global Styles */
@font-face {
  font-family: 'Gotham';
  src: url('./fonts/GothamMedium.ttf') format('ttf'),
       url('./fonts/GothamLight.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Gotham', sans-serif;
  background-color: #343432;
  color: #fff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.container {
  text-align: center;
  padding: 20px;
  max-width: 100%; /* Ensure container doesn't exceed viewport */
  overflow: hidden; /* Avoid overflow */
}

.logo img {
  width: auto;
  max-width: 80vw; /* Constrain the logo's maximum size */
  max-height: 60vh; /* Maintain aspect ratio */
}

h1 {
  font-size: 30px; /* Slightly smaller font size */
  margin-top: 20px;
  color: #EFBE54; /* Golden-yellow color */
  
}

p {
  font-size: 18px;
  margin-top: 10px;
}

.social-icons {
  margin-top: 30px;
}

.social-icons a {
  margin: 0 10px;
  display: inline-block;
  transition: transform 0.3s ease;
}

.social-icons a:hover {
  transform: scale(1.1);
}
